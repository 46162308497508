import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaust, exhaustMap, map, tap } from 'rxjs/operators';
import { Chat } from 'src/app/services/chat.service';
import { Domain } from 'src/app/services/domain.service';
import { SocketService } from 'src/app/services/socket.service';
import { Survey, SurveyOption, SurveysService } from 'src/app/services/surveys.service';
import { FROM_FOREGROUND } from '../actions/events.actions';
import { SOCKET_CONNECTION } from '../actions/socket.actions';
import { answerSurveyFailure, answerSurveySuccess, ANSWER_SURVEY, loadSurveysFailure, loadSurveysSuccess, LOAD_SURVEYS } from '../actions/survey.actions';

@Injectable()
export class SurveyEffects {
  loadSurveys$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOAD_SURVEYS, FROM_FOREGROUND),
      exhaustMap(() => {
        return this.surveyService.all({ active: true, past: false }).pipe(
          map((surveys) => loadSurveysSuccess({ surveys })),
          catchError((error) => of(loadSurveysFailure({ error })))
        )
      })
    );
  });

  answerSurvey$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ANSWER_SURVEY),
      exhaustMap(({ survey, options, custom_answer }: { survey: Survey; options: SurveyOption[]; custom_answer?: string }) => {
        return this.surveyService.answer({ survey, options, custom_answer }).pipe(
          map((response: any) => {  
            return answerSurveySuccess({ survey, options: response });
          }),
          catchError((error) => of(answerSurveyFailure({ error })))
        )
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private surveyService: SurveysService,
  ) { }
}
