import { Injectable, NgZone } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { FCM } from '@capacitor-community/fcm';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
} from '@capacitor/push-notifications';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Badge } from '@capawesome/capacitor-badge';
import { AlertService } from './alert.service';
import { User } from './auth.service';
import { selectDomain, selectUser } from 'src/app/store/selectors/auth.selectors';
import { setDomain } from 'src/app/store/actions/auth.actions';
import { filter, take } from 'rxjs/operators';
import { Domain } from 'src/app/services/domain.service';

@Injectable({
  providedIn: 'root',
})
export class FcmService {
  public fcmToken: string;
  private user: User;
  private pendingNotificationData: any = null;

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private store: Store,
    private alertService: AlertService
  ) {
    if (Capacitor.isNativePlatform()) {

      PushNotifications.addListener('registration', async ({ value }) => {
        let token = value; // Push token for Android

        // Get FCM token instead the APN one returned by Capacitor
        if (Capacitor.getPlatform() === 'ios') {
          const { token: fcm_token } = await FCM.getToken();
          token = fcm_token;
        }
        console.log('FCM token', token);
        this.fcmToken = token;
      });

      PushNotifications.addListener(
        'pushNotificationReceived',
        ({ title, body, data }: PushNotificationSchema) => {
          if (data?.article) {
            this.alertService.push(body, {
              icon: 'file-text',
              route: `article/${data.article}`,
              title,
              data,
            });
          }
          if (data?.chat) {
            this.alertService.push(body, {
              icon: 'message-circle',
              route: `chat/${data.chat}`,
              title,
              data,
            });
          }
          if (data?.survey) {
            this.alertService.push(body, {
              icon: 'megaphone',
              route: `sondage/${data.survey}`,
              title,
              data,
            });
          }
        }
      );

      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        ({ notification }: ActionPerformed) => {
          const data = notification.data;
          this.processNotificationAction(data);
        }
      );

      window.addEventListener('pushNotificationActionPerformed', (event: any) => {
        const data = event.detail.notification;
        this.processNotificationAction(data);
      });

      this.store
        .select(selectUser)
        .pipe(filter((user: User | null): user is User => !!user))
        .subscribe((user: User) => {
          this.user = user;
          if (this.pendingNotificationData) {
            this.handleNotificationAction(this.pendingNotificationData);
            this.pendingNotificationData = null;
          }
        });
    }
  }

  private processNotificationAction(data: any) {
    if (this.user) {
      this.handleNotificationAction(data);
    } else {
      this.pendingNotificationData = data;
    }
  }


  private handleNotificationAction(data: any) {
    const { domain } = data;

    this.switchDomainIfNeeded(domain)
      .then(() => {
        this.ngZone.run(() => {
          Badge.clear();
          this.handleNavigation(data);
        });
      })
      .catch((error) => {
        console.error('Domain switch failed:', error);
      });
  }


  private switchDomainIfNeeded(domainUuid: string): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        if (!domainUuid) {
          return resolve();
        }
  
        this.store
          .select(selectDomain)
          .pipe(
            filter((currentDomain: Domain | null): currentDomain is Domain => !!currentDomain),
            take(1)
          )
          .subscribe(
            (currentDomain: Domain) => {
              if (currentDomain.uuid !== domainUuid) {
                if (this.user && this.user.domains) {
                  const targetDomain = this.user.domains.find(
                    (d: Domain) => d.uuid === domainUuid
                  );
                  if (targetDomain) {
                    this.store.dispatch(setDomain({ domain: targetDomain }));
                    this.store
                      .select(selectDomain)
                      .pipe(
                        filter(
                          (domain: Domain | null): domain is Domain =>
                            !!domain && domain.uuid === domainUuid
                        ),
                        take(1)
                      )
                      .subscribe(
                        () => {
                          resolve();
                        },
                        (error) => {
                          reject(error);
                        }
                      );
                  } else {
                    reject(new Error('Access to the domain is denied.'));
                  }
                } else {
                  reject(new Error('User domains not available.'));
                }
              } else {
                resolve();
              }
            },
            (error) => {
              reject(error);
            }
          );
      } catch (error) {
        reject(error);
      }
    });
  }  

  handleNavigation(data: any) {
    if (data?.article) {
      this.router.navigate([`article/${data.article}`]);
    } else if (data?.chat) {
      this.router.navigate([`chat/${data.chat}`]);
    } else if (data?.survey) {
      setTimeout(() => {
        this.router.navigate([`sondage/${data.survey}`]);
      }, 1000);
    } else if (data?.surveygroup) {
      setTimeout(() => {
        this.router.navigate([`sondage/${data.surveygroup}`]);
      }, 1000);
  }
}

  register(): void {
    if (Capacitor.isNativePlatform()) {
      PushNotifications.checkPermissions().then((checkPermission) => {
        PushNotifications.requestPermissions().then((permission) => {
          if (permission.receive === 'granted') {
            PushNotifications.register();
          } else {
            console.error('No Permission for Notifications!');
          }
        });
      });
    }
  }

  async subscribe(topic: string) {
    if (Capacitor.isNativePlatform()) {
      await FCM.subscribeTo({ topic });
    }
  }

  async unsubscribe(topic: string) {
    if (Capacitor.isNativePlatform()) {
      await FCM.unsubscribeFrom({ topic });
    }
  }

  async getToken() {
    try {
      if (Capacitor.isNativePlatform()) {
        const { token } = await FCM.getToken();
        this.fcmToken = token;
      }
    } catch (error) {
      console.error('Error getting FCM token:', error);
      throw error;
    }
  }

  getFcmToken() {
    return this.fcmToken;
  }
}
