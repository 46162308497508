import { createReducer, on } from '@ngrx/store';
import { AuthResponse, User } from 'src/app/services/auth.service';
import { Domain } from 'src/app/services/domain.service';
import * as authActions from 'src/app/store/actions/auth.actions';

export interface AuthState {
  isConnected: boolean;
  passwordRequired: boolean;
  verificationRequired: boolean;
  domains: Domain[];
  email: string;
  token: string;
  roles: Array<string>;
  user: User;
  domain: Domain;
  isLoading: boolean;
  error: any;
}

export const initialState: AuthState = {
  isConnected: false,
  passwordRequired: false,
  verificationRequired: false,
  domains: null,
  email: null,
  token: '',
  roles: [],
  user: null,
  domain: null,
  isLoading: false,
  error: null,
};

const onLoading = (state: AuthState) => ({
  ...state,
  isLoading: true,
});

const onFailure = (state: AuthState, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

const onSuccess = (state: AuthState) => ({
  ...state,
  isLoading: false,
  error: null,
});

const onLoginSuccess = (state: AuthState, { token, user }: AuthResponse) => ({
  ...state,
  token,
  isConnected: true,
  user,
});

const onGetTokenSuccess = (state: AuthState, { token }: { token: string }) => ({
  ...state,
  token,
});

const onLogout = (state: AuthState) => ({
  ...state,
  isLoading: true,
});

const onSetEmail = (state: AuthState, { email }) => ({
  ...state,
  email,
});

const onGetUserSuccess = (state: AuthState, { user }: { user: User }) => ({
  ...state,
  user,
  isConnected: true,
});

const onGetDomainSuccess = (state: AuthState, { domain }: { domain: Domain }) => ({
  ...state,
  isLoading: false,
  domain,
});

const onUpdateUserSuccess = (state: AuthState, { user }: { user: User }) => {
  return {
    ...state,
    user,
    isLoading: false,
  };
};

const onCheckEmail = (state: AuthState, { email }: { email: string }) => {
  return {
    ...state,
    isLoading: false,
    email,
  };
};

const onCheckEmailSuccess = (state: AuthState, { needPass, needVerification, domains }: { needPass: boolean; needVerification: boolean, domains: Domain[] }): AuthState => {
  return {
    ...state,
    isLoading: false,
    domains,
    passwordRequired: needPass,
    verificationRequired: needVerification,
    domain: domains[0],
  };
};

const onSetDomain = (state: AuthState, { domain }: { domain: Domain }): AuthState => {
  return {
    ...state,
    domain,
    domains: state.domains,
  };
};

const onClearDomains = (state: AuthState): AuthState => {
  return {
    ...state,
    domain: null,
    domains: null,
  };
};

const onCreatePasswordSuccess = (state: AuthState): AuthState => {
  return {
    ...state,
    passwordRequired: false,
  };
};

const onToggleNotificationsSuccess = (state: AuthState, { news }): AuthState => {
  return {
    ...state,
    user: {
      ...state.user,
      notifications: {
        ...state.user.notifications,
        news,
      },
    },
  };
};

const onToggleSurveysNotificationsSuccess = (state: AuthState, { surveys }): AuthState => {
  return {
    ...state,
    user: {
      ...state.user,
      notifications: {
        ...state.user.notifications,
        surveys,
      },
    },
  };
};

const onGetRolesSuccess = (state: AuthState, { roles }: { roles: Array<string> }): AuthState => {
  return {
    ...state,
    isLoading: false,
    roles,
  };
};

export const reducer = createReducer(
  initialState,
  on(authActions.setEmail, onSetEmail),
  on(authActions.setDomain, onSetDomain),
  on(authActions.loginSuccess, onLoginSuccess),
  on(authActions.getTokenSuccess, onGetTokenSuccess),
  on(authActions.logout, onLogout),
  on(authActions.getUserSuccess, onGetUserSuccess),
  on(authActions.getDomainSuccess, onGetDomainSuccess),
  on(authActions.updateUserSuccess, onUpdateUserSuccess),
  on(authActions.checkEmail, onCheckEmail),
  on(authActions.checkEmailSuccess, onCheckEmailSuccess),
  on(authActions.clearDomains, onClearDomains),
  on(authActions.createPasswordSuccess, onCreatePasswordSuccess),
  on(authActions.toggleNotificationsSuccess, onToggleNotificationsSuccess),
  on(authActions.toggleSurveysNotificationsSuccess, onToggleSurveysNotificationsSuccess),
  on(authActions.getRolesSuccess, onGetRolesSuccess),
  on(
    authActions.login,
    authActions.updateUser,
    authActions.checkEmail,
    authActions.createPassword,
    authActions.toggleNotifications,
    authActions.toggleSurveysNotifications,
    authActions.getRoles,
    onLoading
  ),
  on(
    authActions.loginFailure,
    authActions.updateUserFailure,
    authActions.logoutFailure,
    authActions.checkEmailFailure,
    authActions.createPasswordFailure,
    onFailure,
  ),
  on(
    authActions.loginSuccess,
    authActions.createPasswordSuccess,
    onSuccess,
  ),
  on(
    authActions.setDomain,
    onSetDomain
  )
);
